import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form } from 'antd'
import Forms from 'components/Forms/Forms'
import { Container } from 'components/Container/Container'
import { Menu } from '../../components/Menu/Menu'
import { Banner } from '../../components/Banner/Banner'
import { ContractCategories } from '../../features/ContractCategories/ContractCategories'
import { UserContractCategories } from '../../features/UserContractCategories/UserContractCategories'
import {
  createUser,
  fetchContractCategories,
  fetchUserData,
  getHealthSystem,
  updateUser
} from './api'
import { fetchHealthSystemsList } from 'redux/store/healthSystemsList/slice'
import { getUser } from 'redux/store/user/getters'
import useRouter from 'hooks/useRouter'
import { USER_PROFILE_BREADCRUMBS } from './constants'
import { setLoading } from 'redux/store/common/slice'
import history from 'router/history'
import defineAbilityFor from '../../features/Permission/ability'
import { updateUserProfile } from '../../features/UserProfile/api'
import { useCRUDUserContext } from './Provider/CRUDUserProvider'
import { notification } from '../../components/Notification'
import { PROFILE_TABS } from './constants'
import { ACTIONS, SUBJECTS } from '../../features/Permission'
import {
  CategoriesInitialState,
  ContractCategoryItem
} from '../../features/ContractCategories/types'
import { TUserForm } from 'components/Forms/forms.user.d'
import './styles.scss'

export const CRUDUser = () => {
  const [isActive, setIsActive] = useState(false)
  const [contractCategories, setContractCategories] = useState<
    ContractCategoryItem[]
  >([])
  const {
    state: { userData, totalNumber },
    actions: { setUserData }
  } = useCRUDUserContext()
  const categoriesInitialState: CategoriesInitialState = useMemo(
    () => ({
      categories: userData.contract_categories || [],
      pendingCategories: userData.contract_categories_waiting_for_approval || []
    }),
    [
      userData.contract_categories,
      userData.contract_categories_waiting_for_approval
    ]
  )
  const { id } = useParams()
  const TABS = useMemo(
    () => PROFILE_TABS(id === 'new', totalNumber),
    [id, totalNumber]
  )
  const [activeTab, setActiveTab] = useState(TABS[0].key)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [isShow, setIsShow] = useState(false)
  const { query } = useRouter()
  const [form] = Form.useForm()
  const watchRole: string = Form.useWatch('role', form)
  const profileUserRole: string = watchRole || (userData.role as string)
  const fromCommunity = query.fromCommunity
  const ability = defineAbilityFor(profileUserRole, user, userData.uuid ?? '')

  const currentUserCanSeeListOfCC = ability.can(
    ACTIONS.VIEW,
    SUBJECTS.USER_CONTRACT_CATEGORIES
  )

  const isCCTabAvailable = useMemo(
    () =>
      ability.can(ACTIONS.CRUD, SUBJECTS.APPROVE_PENDING_USERS) ||
      currentUserCanSeeListOfCC,
    [user.role, profileUserRole, currentUserCanSeeListOfCC]
  )

  const userCanViewCommunityLevelCheckboxes = ability.can(
    ACTIONS.VIEW,
    SUBJECTS.USER_CONTRACT_CATEGORIES_COMMUNITY_LEVEL
  )

  const userCanEditCommunityLevelCheckboxes = ability.can(
    ACTIONS.CRUD,
    SUBJECTS.USER_CONTRACT_CATEGORIES_COMMUNITY_LEVEL
  )

  useEffect(() => {
    fetchContractCategories().then((data) => {
      setContractCategories(data?.data?.results)
    })
  }, [])

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(setLoading(true))
      fetchUserData(id).then((data) => {
        const pendingCategories: Partial<TUserForm> = {}
        if (!!data?.data?.contract_categories_waiting_for_approval?.length) {
          pendingCategories.contract_categories_waiting_for_approval =
            data?.data?.contract_categories_waiting_for_approval.filter(
              (i) => i.can_be_approved
            )
        }
        if (data?.data?.community) {
          getHealthSystem(
            data?.data?.community,
            data?.data?.health_system
          ).then(() => {
            setUserData({
              ...data?.data,
              ...pendingCategories,
              ...{
                health_system: data?.data?.health_system,
                avatar:
                  data?.data?.avatar?.file || data?.data?.avatar_logo?.file
              }
            })
          })
        }
        setUserData({
          ...data?.data,
          ...pendingCategories,
          ...{
            avatar: data?.data?.avatar?.file || data?.data?.avatar_logo?.file
          }
        })
        setIsShow(true)
      })
    } else {
      if (query?.hsUser || query?.hsHosUser) {
        const data = {
          health_system: query?.hsUser || query?.hsHosUser
        }
        if (location.search.includes('&role=rca')) {
          data['role'] = 'community_rca'
        }
        if (location.search.includes('&role=stakeholder')) {
          data['role'] = 'community_stakeholder'
        }
        setUserData({ ...data })
      }

      setIsShow(true)
    }
  }, [id])

  useEffect(() => {
    if (user?.community) {
      dispatch(fetchHealthSystemsList(user.community as string))
    }
  }, [user.community])

  const onSubmit = (values: any) => {
    const data: any = {}
    for (const key in values) {
      if (key === 'contract_stewards' || key === 'hospitals') {
        if (
          !!values[key]?.filter((k) => !!Object.values(k).find((i) => !!i))
            ?.length
        )
          data[key] = values[key].filter(
            (k) => !!Object.values(k).find((i) => !!i)
          )
        else if (values[key] === null) data[key] = []
        else if (!values[key]?.length) data[key] = values[key]

        if (key === 'hospitals' && !!data?.hospitals) {
          data?.hospitals?.forEach((i) => delete i?.name)
        }
      } else {
        if (
          (typeof values[key] !== 'number' && !values[key]?.length) ||
          !values[key] ||
          (Array.isArray(values[key]) &&
            typeof values[key][0] === 'object' &&
            !Object.keys(values[key][0]).length)
        ) {
          if (values[key] == 0) {
            data[key] = values[key]
          }
        } else {
          data[key] = values[key]
        }
      }
    }
    if (id === user.uuid) {
      return updateUserProfile({
        first_name: data['first_name'],
        last_name: data['last_name'],
        phone_number: data['phone_number'] || '',
        avatar_id: data['avatar']
      })
        .then(history.back)
        .catch((err) => {
          dispatch(setLoading(false))
          notification.error({ message: err?.data?.email[0] })
        })
    }
    if (id !== user.uuid && id && id !== 'new') {
      return updateUser(id, data)
        .then(history.back)
        .catch((err) => {
          dispatch(setLoading(false))
          if (!!err?.data?.email?.length) {
            notification.error({ message: err?.data?.email[0] })
          }
          throw err
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
    if (id === 'new') {
      return createUser(data)
        .then(() => {
          if (
            location.search.includes('&role=rca') ||
            location.search.includes('&role=stakeholder')
          ) {
            window.close()
          } else history.back()
        })
        .catch((err) => {
          dispatch(setLoading(false))
          if (!!err?.data?.email?.length) {
            notification.error({ message: err?.data?.email[0] })
          }
          if (
            !!err?.data?.contract_stewards &&
            !data.contract_stewards?.length
          ) {
            notification.error({
              message: 'Contract Steward is required'
            })
          } else if (!!err?.data?.contract_stewards) {
            notification.error({
              message: err?.data?.contract_stewards[0]
            })
          }
          throw err
        })
    }
  }

  return (
    <Container
      className="user-profile"
      breadcrumbs={USER_PROFILE_BREADCRUMBS(!!fromCommunity, id)}
      title={id && id !== 'new' ? 'User profile' : 'Create user profile'}
      menu={
        isCCTabAvailable && (
          <Menu
            mode="horizontal"
            onSelect={(e) => setActiveTab(e.key)}
            selectedKeys={[activeTab]}
            defaultSelectedKeys={[activeTab]}
            items={TABS}
          />
        )
      }
    >
      {activeTab === TABS[0].key && (
        <>
          {!!userData.contract_categories_waiting_for_approval?.length && (
            <Banner.Warning text="Some contract categories are awaiting approval." />
          )}
          {isShow && (
            <Forms.User
              onSubmit={onSubmit}
              setIsActive={setIsActive}
              contractCategoriesList={contractCategories}
              isActive={isActive}
              initialState={userData}
              hsHosUser={query?.hsHosUser}
              hsUser={query?.hsUser}
              form={form}
            />
          )}
        </>
      )}
      {activeTab === TABS[1].key && userData.uuid && (
        <>
          {currentUserCanSeeListOfCC ? (
            <UserContractCategories
              role={profileUserRole}
              userId={userData.uuid}
              communityLevelCheckboxesVisible={
                userCanViewCommunityLevelCheckboxes
              }
              communityLevelCheckboxesEditable={
                userCanEditCommunityLevelCheckboxes
              }
            />
          ) : (
            <ContractCategories
              contractCategoriesList={contractCategories || []}
              isActive={isActive}
              setIsActive={setIsActive}
              initialState={categoriesInitialState}
              userData={userData}
            />
          )}
        </>
      )}
    </Container>
  )
}
